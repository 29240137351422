import {
    Alert,
    FormField,
    Select,
    SpaceBetween,
} from '@cloudscape-design/components';
import { OptionDefinition } from '@cloudscape-design/components/internal/components/option/interfaces';
import React, { useCallback, useEffect, useState } from 'react';

import {
    API_URL_UM_RESOURCE_REGIONS,
    API_URL_UM_RESOURCE_COUNTRIES,
    API_URL_UM_RESOURCE_SITES,
    API_URL_UM_RESOURCE_BUILDINGS,
    API_URL_UM_RESOURCE_FLOORS,
    API_URL_UM_RESOURCE_ROOMS,
    API_URL_PATH_LOCATIONS_HIERARCHY,
} from 'constants/urls';
import { locationAPI, deviceManagerAPI } from 'api';
import { addRecentlyUsed, selectLocationProps } from 'utils';
import useFetchWithReactQuery from 'hooks/useFetchWithReactQuery';
import { LocationItem, LocationType } from 'types/custom';

type LocationProps = {
    region: OptionDefinition | null;
    country: OptionDefinition | null;
    site: OptionDefinition | null;
    building?: OptionDefinition | null;
    floor?: OptionDefinition | null;
    room?: OptionDefinition | null;
    disableFormFields: boolean;
    regionError?: string;
    countryError?: string;
    siteError?: string;
    setRegion: React.Dispatch<React.SetStateAction<OptionDefinition | null>>;
    setCountry: React.Dispatch<React.SetStateAction<OptionDefinition | null>>;
    setSite: React.Dispatch<React.SetStateAction<OptionDefinition | null>>;
    setBuilding?: React.Dispatch<React.SetStateAction<OptionDefinition | null>>;
    setFloor?: React.Dispatch<React.SetStateAction<OptionDefinition | null>>;
    setRoom?: React.Dispatch<React.SetStateAction<OptionDefinition | null>>;
    deepLocations: boolean;
    hasSubmitted?: boolean;
};

const Location = ({
    region,
    country,
    site,
    building,
    floor,
    room,
    disableFormFields,
    regionError,
    countryError,
    siteError,
    setRegion,
    setCountry,
    setSite,
    setBuilding,
    setFloor,
    setRoom,
    deepLocations,
    hasSubmitted,
}: LocationProps) => {
    const [allRegions, setAllRegions] = useState<LocationItem[]>([]);
    const [allCountries, setAllCountries] = useState<LocationItem[]>([]);
    const [allSites, setAllSites] = useState<LocationItem[]>([]);
    const [allBuildings, setAllBuildings] = useState<LocationItem[]>([]);
    const [allFloors, setAllFloors] = useState<LocationItem[]>([]);
    const [allRooms, setAllRooms] = useState<LocationItem[]>([]);

    const handleOnSelect = useCallback(async (selectType: LocationType, selectedValue: OptionDefinition) => {
        const selectTypeToHandlerMap: { [key in LocationType]: React.Dispatch<React.SetStateAction<OptionDefinition | null>> | undefined } = {
            region: setRegion,
            country: setCountry,
            site: setSite,
            building: setBuilding,
            floor: setFloor,
            room: setRoom
        };

        const setHandler = selectTypeToHandlerMap[selectType];
        if (setHandler) {
            setHandler({ label: selectedValue.label, value: selectedValue.value });
        }

    }, [setBuilding, setCountry, setFloor, setRegion, setRoom, setSite]);

    const { data: storedData, error: storedError } = useFetchWithReactQuery(
        {
            key: 'stored-data',
            axiosInstance: deviceManagerAPI,
            url: API_URL_PATH_LOCATIONS_HIERARCHY,
        },
    );

    const { data: regionsData, error: regionsError, isLoading: regionsLoading } = useFetchWithReactQuery(
        {
            key: `regions`,
            axiosInstance: locationAPI,
            url: `${API_URL_UM_RESOURCE_REGIONS}?withchild=true`,
        },
    );

    const { data: countriesData, error: countriesError, isLoading: countriesLoading } = useFetchWithReactQuery(
        {
            key: `countries-${region?.value}`,
            axiosInstance: locationAPI,
            url: `${API_URL_UM_RESOURCE_COUNTRIES}/${region?.value}?withchild=true`,
            enabled: !!region?.value,
        },
    );

    const { data: sitesData, error: sitesError, isLoading: sitesLoading } = useFetchWithReactQuery(
        {
            key: `sites-${country?.value}`,
            axiosInstance: locationAPI,
            url: `${API_URL_UM_RESOURCE_SITES}/${country?.value}?withchild=true`,
            enabled: !!country?.value,
        },
    );

    const { data: buildingData, error: buildingError, isLoading: buildingLoading } = useFetchWithReactQuery(
        {
            key: `buildings-${site?.value}`,
            axiosInstance: locationAPI,
            url: `${API_URL_UM_RESOURCE_BUILDINGS}/${site?.value}?withchild=true`,
            enabled: !!site?.value,
        },
    );

    const { data: floorsData, error: floorsError, isLoading: floorsLoading } = useFetchWithReactQuery(
        {
            key: `floors-${building?.value}`,
            axiosInstance: locationAPI,
            url: `${API_URL_UM_RESOURCE_FLOORS}/${building?.value}?withchild=true`,
            enabled: !!building?.value,
        },
    );

    const { data: roomsData, error: roomsError, isLoading: roomsLoading } = useFetchWithReactQuery(
        {
            key: `rooms-${floor?.value}`,
            axiosInstance: locationAPI,
            url: `${API_URL_UM_RESOURCE_ROOMS}/${floor?.value}?withchild=true`,
            enabled: !!floor?.value,
        },
    );

    useEffect(() => {
        if (regionsData) {
            const regionsArray: any[] = regionsData.child;
            const regionsList = regionsArray?.map((data) =>
                selectLocationProps(data, 'region')
            );
            setAllRegions(regionsList || []);
        }

        if (countriesData) {
            const countriesArray: any[] = countriesData.child;
            const countriesList = countriesArray?.map((data) =>
                selectLocationProps(data, 'country')
            );
            setAllCountries(countriesList || []);
        }

        if (sitesData) {
            const sitesArray: any[] = sitesData.child;
            const sitesList = sitesArray?.map((data) =>
                selectLocationProps(data, 'site')
            );
            setAllSites(sitesList || []);
        }

        if (buildingData) {
            const buildingsArray: any[] = buildingData.child;
            const buildingsList = buildingsArray?.map((data) =>
                selectLocationProps(data, 'building')
            );
            setAllBuildings(buildingsList || []);
        }

        if (floorsData) {
            const floorsArray: any[] = floorsData.child;
            const floorsList = floorsArray?.map((data) =>
                selectLocationProps(data, 'floor')
            );
            setAllFloors(floorsList || []);
        }

        if (roomsData) {
            const roomsArray: any[] = roomsData.child;
            const roomsList = roomsArray?.map((data) =>
                selectLocationProps(data, 'room')
            );
            setAllRooms(roomsList || []);
        }
    }, [buildingData, countriesData, floorsData, regionsData, roomsData, sitesData]);

    return (
        <SpaceBetween direction='vertical' size='l'>
            <FormField label='Region' errorText={regionsError?.message || (hasSubmitted && regionError)}>
                <Select
                    selectedOption={allRegions.find(item => item.id.toString() === region?.value?.toString()) || null}
                    onChange={({ detail }) => handleOnSelect('region', detail.selectedOption)}
                    placeholder='Choose an option'
                    loadingText='Loading regions'
                    options={addRecentlyUsed(allRegions, storedData)}
                    statusType={regionsLoading ? 'loading' : 'finished'}
                    disabled={disableFormFields}
                    empty='No regions found'
                    filteringType='manual'
                />
            </FormField>
            <FormField label='Country' errorText={countriesError?.message || (hasSubmitted && countryError)}>
                <Select
                    selectedOption={allCountries.find(item => item.id.toString() === country?.value?.toString()) || null}
                    onChange={({ detail }) => handleOnSelect('country', detail.selectedOption)}
                    placeholder='Choose an option'
                    loadingText='Loading countries'
                    options={addRecentlyUsed(allCountries, storedData, [region?.value], countriesLoading)}
                    statusType={countriesLoading ? 'loading' : 'finished'}
                    disabled={!region || disableFormFields}
                    empty='No countries found'
                    filteringType='manual'
                />
            </FormField>
            <FormField label='Site' errorText={sitesError?.message || (hasSubmitted && siteError)}>
                <Select
                    selectedOption={allSites.find(item => item.id.toString() === site?.value?.toString()) || null}
                    onChange={({ detail }) => handleOnSelect('site', detail.selectedOption)}
                    placeholder='Choose an option'
                    loadingText='Loading sites'
                    options={addRecentlyUsed(allSites, storedData, [region?.value, country?.value], sitesLoading)}
                    statusType={sitesLoading ? 'loading' : 'finished'}
                    disabled={!country || disableFormFields}
                    empty='No sites found'
                    filteringType='manual'
                />
            </FormField>
            {deepLocations && (
                <>
                    <FormField label='Building' errorText={buildingError?.message}>
                        <Select
                            selectedOption={allBuildings.find(item => item.id.toString() === building?.value?.toString()) || null}
                            onChange={({ detail }) => handleOnSelect('building', detail.selectedOption)}
                            placeholder='Choose an option'
                            loadingText='Loading buildings'
                            options={addRecentlyUsed(allBuildings, storedData, [region?.value, country?.value, site?.value], buildingLoading)}
                            statusType={buildingLoading ? 'loading' : 'finished'}
                            disabled={!site || disableFormFields}
                            empty='No buildings found'
                            filteringType='manual'
                        />
                    </FormField>
                    <FormField label='Floor' errorText={floorsError?.message}>
                        <Select
                            selectedOption={allFloors.find(item => item.id.toString() === floor?.value?.toString()) || null}
                            onChange={({ detail }) => handleOnSelect('floor', detail.selectedOption)}
                            placeholder='Choose an option'
                            loadingText='Loading floors'
                            options={addRecentlyUsed(allFloors, storedData, [region?.value, country?.value, site?.value, building?.value], floorsLoading)}
                            statusType={floorsLoading ? 'loading' : 'finished'}
                            disabled={!building || disableFormFields}
                            empty='No floors found'
                            filteringType='manual'
                        />
                    </FormField>
                    <FormField label='Room' errorText={roomsError?.message}>
                        <Select
                            selectedOption={allRooms.find(item => item.id.toString() === room?.value?.toString()) || null}
                            onChange={({ detail }) => handleOnSelect('room', detail.selectedOption)}
                            placeholder='Choose an option'
                            loadingText='Loading rooms'
                            options={addRecentlyUsed(allRooms, storedData, [region?.value, country?.value, site?.value, building?.value, floor?.value], roomsLoading)}
                            statusType={roomsLoading ? 'loading' : 'finished'}
                            disabled={!floor || disableFormFields}
                            empty='No room found'
                            filteringType='manual'
                        />
                    </FormField>
                </>
            )}
            {storedError && <Alert type="warning" header='Error loading recently used locations'>{storedError.message}</Alert>}
        </SpaceBetween>
    );
};

export default Location;
