import {
    Alert,
    Button,
    ColumnLayout,
    DateRangePicker,
    DateRangePickerProps,
    ExpandableSection,
    Form,
    FormField,
    Input,
    Modal,
    SegmentedControl,
    Select,
    SelectProps,
    SpaceBetween,
    Textarea,
    Toggle,
} from '@cloudscape-design/components';
import { OptionDefinition } from '@cloudscape-design/components/internal/components/option/interfaces';
import { deviceManagerAPI } from 'api';
import { usePageLayoutContext } from 'components/common/layout';
import { snakeCaseToNormalCase } from 'components/device-card/utils';
import IncidentStatusBadge from 'components/incident-status/IncidentStatusBadge';
import { API_URL_PATH_DM_DEVICE, API_URL_PATH_DM_RULE } from 'constants/urls';
import useFetch from 'hooks/useFetch';
import { useEffect, useState } from 'react';
import { AlertRule, Asset, AssetAlertRuleStatus, IncidentStatus } from 'types/custom';
import AlertStatusIndicator from '../AlertStatusIndicator';
import { notificationFrequencyProps, relativeToSeconds, secondsToRelative } from './utils';

// Supports creating and editing alert rules
const CreateAlertModal = ({
    visible,
    setVisible,
    rulesRefetch,
    escalationPlansList,
    device,
    alertRule,
}: {
    visible: boolean;
    setVisible: (state: boolean) => void;
    rulesRefetch: () => void;
    escalationPlansList: OptionDefinition[];
    device: Asset;
    alertRule?: AlertRule;
}) => {
    const { setNotification } = usePageLayoutContext();

    const [disableAllFields, setDisableAllFields] = useState(false);
    const [allMeasures, setAllMeasures] = useState<SelectProps.Options>([]);

    const [status, setStatus] = useState<AssetAlertRuleStatus | undefined>(alertRule?.status ?? undefined);
    const [measureName, setMeasureName] = useState<OptionDefinition | null>(alertRule?.measure ? {
        label: snakeCaseToNormalCase(alertRule.measure),
        value: alertRule.measure,
    } : null);
    const [thresholdCodeId, setThresholdCodeId] = useState<string | undefined>(alertRule?.threshold_code || undefined);
    const [thresholdValue, setThresholdValue] = useState(alertRule?.threshold_value?.toString() || '');
    const [deadbandValue, setDeadbandValue] = useState<DateRangePickerProps.Value | null>(secondsToRelative(alertRule?.deadband_period || 0, true));
    const [monitoringForward, setMonitoringForward] = useState(alertRule?.forward || false);
    const [selectedEscalationPlan, setSelectedEscalationPlan] = useState<OptionDefinition>({
        label: alertRule?.escalation_plan ?? 'None',
        value: alertRule?.escalation_plan ?? '',
    });

    const [notifOpen, setNotifOpen] = useState<DateRangePickerProps.Value | null>(secondsToRelative(alertRule?.notification_frequency?.OPEN || 0));
    const [notifAcknowledged, setNotifAcknowledged] = useState<DateRangePickerProps.Value | null>(secondsToRelative(alertRule?.notification_frequency?.ACKNOWLEDGED || 0));
    const [notifClearance, setNotifClearance] = useState<DateRangePickerProps.Value | null>(secondsToRelative(alertRule?.notification_frequency?.CLEARED || 0));
    const [notesValue, setNotesValue] = useState(alertRule?.notes || '');

    useEffect(() => {
        if (device.state) {
            setAllMeasures(Object.keys(device.state).map((measure) => ({ label: snakeCaseToNormalCase(measure), value: measure })));
        }
    }, [device.name]);

    const {
        fetchData: patchAlertRule,
        error: patchAlertError,
        loading: patchAlertRuleLoading,
    } = useFetch(
        {
            axiosInstance: deviceManagerAPI,
            method: 'PATCH',
            url: alertRule?.id ? `${API_URL_PATH_DM_RULE}/${alertRule?.id}` : `${API_URL_PATH_DM_DEVICE}/${device.name}/rules`,
            data: {
                measure: measureName?.value,
                deadband_period: relativeToSeconds(deadbandValue as DateRangePickerProps.RelativeValue, 0),
                threshold_code: thresholdCodeId,
                threshold_value: +thresholdValue,
                escalation_plan: selectedEscalationPlan?.value,
                notification_frequency: {
                    OPEN: relativeToSeconds(notifOpen as DateRangePickerProps.RelativeValue),
                    ACKNOWLEDGED: relativeToSeconds(notifAcknowledged as DateRangePickerProps.RelativeValue),
                    CLEARED: relativeToSeconds(notifClearance as DateRangePickerProps.RelativeValue),
                },
                notes: notesValue,
                status: status,
                forward: monitoringForward,
            } as Partial<AlertRule>,
        },
        { manual: true }
    );

    const handleSubmit = async (event: React.SyntheticEvent) => {
        event.preventDefault();
        setDisableAllFields(true);

        const response = await patchAlertRule();
        if (response && response.status >= 200 && response.status < 300) {
            rulesRefetch();
            setNotification([{
                type: 'success',
                content: `${alertRule ? 'Edited' : 'Created'} alert rule for device ${device.name} successfully`,
            }]);
        } else {
            setNotification([
                {
                    type: 'error',
                    content: response?.data?.message?.toString() || 'Bad request',
                },
            ]);
        }
        setVisible(false);
        setDisableAllFields(false);
    };

    return (
        <Modal
            onDismiss={() => setVisible(false)}
            visible={visible}
            header={`${alertRule ? 'Edit' : 'Create'} Alert Rule`}
            footer={alertRule ? `Rule ID: ${alertRule.id}` : ''}
            size='medium'
        >
            <form onSubmit={handleSubmit}>
                <Form
                    actions={
                        <SpaceBetween direction='horizontal' size='xs'>
                            <Button
                                variant='normal'
                                formAction='none'
                                onClick={() => setVisible(false)}
                                disabled={patchAlertRuleLoading}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant='primary'
                                loading={patchAlertRuleLoading}
                            >
                                Save
                            </Button>
                        </SpaceBetween>
                    }
                    errorText={patchAlertError}
                >
                    <ColumnLayout columns={1} variant='text-grid'>
                        {alertRule && (
                            <Alert header='You are editing an existing rule'>
                                Note that your changes will not be considered on the existing incidents from this alert.
                            </Alert>
                        )}
                        <SpaceBetween size='s' direction='vertical'>
                            {alertRule && <FormField label='Status'>
                                <Toggle checked={status === AssetAlertRuleStatus.ENABLED} onChange={({ detail }) =>
                                    setStatus(detail.checked ? AssetAlertRuleStatus.ENABLED : AssetAlertRuleStatus.DISABLED)
                                }><AlertStatusIndicator status={status}/></Toggle>
                            </FormField>}

                            <FormField label='Measure'>
                                <Select
                                    selectedOption={measureName}
                                    disabled={disableAllFields}
                                    onChange={({ detail }) => setMeasureName(detail.selectedOption)}
                                    placeholder='Choose an option'
                                    loadingText='Loading measures'
                                    options={allMeasures}
                                    empty='No options'
                                />
                            </FormField>

                            <FormField label='Type'>
                                <SegmentedControl
                                    selectedId={thresholdCodeId || null}
                                    onChange={({ detail }) => setThresholdCodeId(detail.selectedId)}
                                    options={[
                                        {
                                            text: 'Low-Low',
                                            id: 'LL',
                                            disabled: disableAllFields,
                                        },
                                        {
                                            text: 'Low',
                                            id: 'L',
                                            disabled: disableAllFields,
                                        },
                                        {
                                            text: 'High',
                                            id: 'H',
                                            disabled: disableAllFields,
                                        },
                                        {
                                            text: 'High-High',
                                            id: 'HH',
                                            disabled: disableAllFields,
                                        },
                                    ]}
                                />
                            </FormField>

                            <ColumnLayout columns={2}>
                                <FormField label='Threshold Value'>
                                    <Input
                                        type='number'
                                        disabled={disableAllFields}
                                        value={thresholdValue}
                                        onChange={(event) => setThresholdValue(event.detail.value)}
                                    />
                                </FormField>

                                <FormField label='Deadband'>
                                    <DateRangePicker
                                        onChange={({ detail }) => setDeadbandValue(detail.value)}
                                        value={deadbandValue}
                                        disabled={disableAllFields}
                                        relativeOptions={[
                                            {
                                                key: "previous-10-minutes",
                                                amount: 10,
                                                unit: "minute",
                                                type: "relative"
                                            },
                                            {
                                                key: "previous-30-minutes",
                                                amount: 30,
                                                unit: "minute",
                                                type: "relative"
                                            },
                                            {
                                                key: "previous-1-hours",
                                                amount: 1,
                                                unit: "hour",
                                                type: "relative"
                                            },
                                            {
                                                key: "previous-2-hours",
                                                amount: 2,
                                                unit: "hour",
                                                type: "relative"
                                            }
                                        ]}
                                        i18nStrings={{
                                            customRelativeRangeDurationLabel: 'Duration',
                                            customRelativeRangeDurationPlaceholder: 'Enter duration',
                                            customRelativeRangeOptionLabel: 'Custom duration',
                                            customRelativeRangeUnitLabel: 'Unit of time',
                                            formatRelativeRange: (e) => {
                                                const n = 1 === e.amount ? e.unit : `${e.unit}s`;
                                                return `${e.amount} ${n}`;
                                            },
                                            formatUnit: (e, n) => (1 === n ? e : `${e}s`),
                                            relativeRangeSelectionHeading: 'Alarm will remain pending for',
                                            clearButtonLabel: 'Disable',
                                            cancelButtonLabel: 'Cancel',
                                            applyButtonLabel: 'Apply',
                                        }}
                                        rangeSelectorMode='relative-only'
                                        placeholder='Disabled'
                                        isValidRange={range => !isNaN((range as DateRangePickerProps.RelativeValue)?.amount) ? ({ valid: true }) : ({ valid: false, errorMessage: 'Duration cannot be empty' })}
                                    />
                                </FormField>
                                <FormField label='Escalation Plan'>
                                    <Select
                                        selectedOption={selectedEscalationPlan || null}
                                        disabled={disableAllFields}
                                        onChange={({ detail }) => setSelectedEscalationPlan(detail.selectedOption)}
                                        loadingText='Loading escalation plans'
                                        options={escalationPlansList}
                                        statusType={escalationPlansList.length === 0 ? 'loading' : 'finished'}
                                        errorText='Error fetching escalation plans.'
                                        empty='No escalations plans'
                                    />
                                </FormField>
                                <FormField label='Forward to Central Monitoring'>
                                    <Toggle
                                        onChange={({ detail }) => setMonitoringForward(detail.checked) }
                                        checked={monitoringForward}
                                        disabled={disableAllFields}>
                                        {monitoringForward ? 'Enabled' : 'Disabled'}
                                    </Toggle>
                                </FormField>
                            </ColumnLayout>

                            <FormField label={<span>Notes <i>- optional</i></span>}>
                                <Textarea
                                    disabled={disableAllFields}
                                    value={notesValue}
                                    onChange={(event) => setNotesValue(event.detail.value)}
                                />
                            </FormField>

                            <ExpandableSection headerText="Notification Frequencies">
                                <SpaceBetween size='xs' direction='vertical'>
                                    <span>Configure specific notification intervals to reduce the amount of received notifications.</span>
                                    <FormField label={
                                        <span>When incident is &nbsp;<IncidentStatusBadge status={IncidentStatus.OPEN}/>&nbsp; or &nbsp;<IncidentStatusBadge status={IncidentStatus.FORWARDED}/></span>
                                    }>
                                        <DateRangePicker
                                            {...notificationFrequencyProps}
                                            onChange={({ detail }) => setNotifOpen(detail.value)}
                                            value={notifOpen}
                                            disabled={disableAllFields}
                                            />
                                    </FormField>
                                    <FormField label={
                                        <span>When incident is &nbsp;<IncidentStatusBadge status={IncidentStatus.ACKNOWLEDGED}/></span>
                                    }>
                                        <DateRangePicker
                                            {...notificationFrequencyProps}
                                            onChange={({ detail }) => setNotifAcknowledged(detail.value)}
                                            value={notifAcknowledged}
                                            disabled={disableAllFields}
                                            />
                                    </FormField>
                                    <FormField label={
                                        <span>When incident is &nbsp;<IncidentStatusBadge status={IncidentStatus.CLEARED}/></span>
                                    }>
                                        <DateRangePicker
                                            {...notificationFrequencyProps}
                                            onChange={({ detail }) => setNotifClearance(detail.value)}
                                            value={notifClearance}
                                            disabled={disableAllFields}
                                            />
                                    </FormField>
                                </SpaceBetween>
                            </ExpandableSection>
                        </SpaceBetween>
                    </ColumnLayout>
                </Form>
            </form>
        </Modal>
    );
};

export default CreateAlertModal;
