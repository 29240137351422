import { SimpleTreeView } from "@mui/x-tree-view";
import { DndProvider } from "react-dnd"
import { useEffect, useRef, useState } from "react";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Alert, AlertTitle, CircularProgress } from "@mui/material";

import useFetch from "hooks/useFetch";
import { getAuthConfig } from "utils";
import { deviceManagerAPI } from "api";
import HierarchyItem from "./HierarchyItem";
import { Asset, DeviceState, Hierarchy, HierarchyNode, MinimalNode, Tag } from "types/custom";
import { usePageLayoutContext } from "components/common/layout";
import { deleteNode, toMinimalNode, findSourceNodeAndParentInTree, makeNodeFromDevice, isInHierarchy } from "./utils";
import { API_URL_PATH_HIERARCHY, API_URL_PATH_TAG, API_URL_PATH_DM_CLAIMED, API_URL_PATH_TAG_BY_HIERARCHY } from "constants/urls";
import { useDashboardContext } from "providers/DashboardProvider";
import useFetchWithReactQuery from "hooks/useFetchWithReactQuery";
import { Modal, SelectProps } from "@cloudscape-design/components";
import DeviceTabs from "components/device-manager/DeviceTabs";

const HierarchyTree = () => {
  const [hierarchyTree, setHierarchyTree] = useState<HierarchyNode>();
  const [enabledDevices, setEnabledDevices] = useState<SelectProps.Options | undefined>([]);
  const [allIds, setAllIds] = useState<string[]>([]);
  const [expanded, setExpanded] = useState<string[]>([]);
  const [expandWith, setExpandWith] = useState<string | null>(null);
  const [devicesPresentInTree, setDevicesPresentInTree] = useState<string[]>([]);
  const [deviceDetailsModal, setDeviceDetailsModal] = useState<string | null>(null);

  const { setNotification } = usePageLayoutContext();

  const { setSelectedNode, setChartDevices, chartDevices, setRolesSelectDisabled, selectedRole, expandAll, setExpandAll } = useDashboardContext();

  const isUpdating = useRef(false);

  const { response: hierarchy, loading: isHierarchyLoading, error: hierarchyError, fetchData: hierarchyFetch } = useFetch<Hierarchy>(
    {
      axiosInstance: deviceManagerAPI,
      method: 'GET',
      url: `${API_URL_PATH_HIERARCHY}/${selectedRole || 'forUser'}`,
    },
    { manual: true }
  );

  useEffect(() => {
    hierarchyFetch();
    setHierarchyTree(undefined);
    setEnabledDevices([]);
    setAllIds([]);
    setDevicesPresentInTree([]);
  }, [selectedRole]);

  const { fetchData: fetchTagById, loading: isTagLoading } = useFetch<HierarchyNode>(
    {
      axiosInstance: deviceManagerAPI,
      method: 'GET',
    },
    { manual: true }
  );

  const { fetchData: allTagsForHierarchy, loading: isAllTagsLoading } = useFetch<{ data: { items: Tag[] } }>({
    axiosInstance: deviceManagerAPI,
    method: 'GET',
  }, { manual: true });

  const {
    data: devicesResponse,
    error: devicesError,
    isLoading: isDevicesLoading,
  } = useFetchWithReactQuery<{ items: Asset[] }>({
    axiosInstance: deviceManagerAPI,
    url: API_URL_PATH_DM_CLAIMED,
    key: 'devices',
  });

  useEffect(() => {
    setRolesSelectDisabled(isHierarchyLoading || isTagLoading || isAllTagsLoading || isDevicesLoading);
  }, [isHierarchyLoading, isTagLoading, isAllTagsLoading, isDevicesLoading]);

  const fetchTag = async (node: HierarchyNode) => {
    if (!allIds.includes(node.id)) setAllIds(allIds => !allIds.includes(node.id) ? [...allIds, node.id] : allIds);
    const tagDataResponse: { data: Tag } | 'canceled' = await fetchTagById(`${API_URL_PATH_TAG}/${node.id}`);
    if (tagDataResponse === 'canceled') return;
    if (!tagDataResponse || !tagDataResponse.data) {
      setNotification([
        {
          type: 'error',
          content: 'Error fetching tag data',
        },
      ]);
      return node
    }
    node.name = tagDataResponse.data.name;
    node.assetList = tagDataResponse.data.assetList;
    return node;
  };

  useEffect(() => {
    (async () => {
      if (!isHierarchyLoading && hierarchy && devicesResponse) {
        const response = await allTagsForHierarchy(`${API_URL_PATH_TAG_BY_HIERARCHY}/${hierarchy.id}`);
        if (response === 'canceled') return;
        if (!response || !response.data) {
          setNotification([
            {
              type: 'error',
              content: 'Error fetching all tags for one level hierarchy',
            },
          ]);
          return
        }
        const nodes = hierarchy.hierarchyTreeData.map(minimalNode => toHierarchyNode(response.data.items, minimalNode)).filter(x => x !== undefined) as HierarchyNode[];

        setExpanded([]); // Not clearing this while reloading the hierarchy will cause expanded tags without devices children, as they're added when they're clicked
        if (nodes?.[0]) {
          setHierarchyTree(nodes[0]); // Use only the first one, a tree with multiple roots is not supported
          handleNodeClick(nodes[0]); // Automatically render and expand first one
        } else {
          setHierarchyTree(undefined);
          setNotification([{
            type: 'error',
            content: 'Hierarchy tree is empty and does not contain a root node. Please recreate from the Role Manager.',
          }]);
        }
      }
    })();
  }, [hierarchy, devicesResponse]);

  useEffect(() => {
    if (expandAll) {
      setExpanded(allIds);
      setExpandAll(false);
    }
  }, [expandAll]);

  const toHierarchyNode = (tags: Tag[], minimalNode: MinimalNode): HierarchyNode => {
    const tag = tags.find(tag => tag.id === minimalNode.tagId);

    setDevicesPresentInTree((prev: string[]) => [...prev, ...(tag?.assetList || [])]);
    setAllIds(allIds => !allIds.includes(minimalNode.tagId) ? [...allIds, minimalNode.tagId] : allIds);

    return {
      id: minimalNode.tagId,
      name: tag?.name || undefined,
      assetList: tag?.assetList?.sort((a,b) => (a || '').localeCompare(b || '', "en", { numeric: true })) || [],
      children: (minimalNode.children || []).map(child => toHierarchyNode(tags, child)).sort((a,b) => (a.name || '').localeCompare(b.name || '', "en", { numeric: true })),
      isDevice: false,
    } as HierarchyNode;
  };

  const handleNodeClick = async (node: HierarchyNode) => {
    setSelectedNode(node);
    if (!node.isRendered) {
      // TODO: Device data should be dynamically loaded, not everything fetched at once (this wouldn't be efficient if there're thousands of devices)
      // Current approach is flexible enough to allow this change in the future (in a way similar to the fetchTag method)
      node.isRendered = true;
      // Obtains devices from the asset list
      const devices = devicesResponse?.items?.filter((x) => node?.assetList?.includes(x.name)).map(makeNodeFromDevice) || [];
      if (!expanded.includes(node.id) && !node.isDevice) setExpandWith(node.id);
      const tagsToFetch = node.children?.filter((child) => !child.children?.length && !child.isDevice) || [];
      // Show the data that's already fetched: tags with asset list (preloaded) and devices. Filters tags that could have been added because of reordering
      node.children = [...node.children.filter(child => !child.isDevice), ...devices] as HierarchyNode[];
      // Fetch the missing tags without blocking the rendering
      Promise.all(tagsToFetch.map((child) => fetchTag(child))).then((newTags) => {
        node.children = node.children.map((child) => newTags.find((tag) => tag && tag.id === child.id) || child);
      });
    }
  };

  useEffect(() => {
    // This is used to ensure expanded will always have the latest available value
    if (expandWith) {
      setExpanded([...expanded, expandWith]);
      setExpandWith(null);
    }
  }, [expandWith]);

  const handleNodeReorder = async (source: HierarchyNode, destination: HierarchyNode) => {
    if (!hierarchyTree || isUpdating.current || source.id === destination.id) return;

    if (destination?.isDevice) {
      setNotification([{
        type: 'error',
        content: 'Items cannot be reordered inside other devices',
      }]);
      return;
    }

    if (isInHierarchy(destination, source)) {
      setNotification([{
        type: 'error',
        content: 'Items cannot be reordered inside one its children',
      }]);
      return;
    }

    const sourceParent = findSourceNodeAndParentInTree(source, hierarchyTree).parent;
    if (!sourceParent || destination.id === sourceParent.id) {
      return;
    }

    isUpdating.current = true;

    // Detaches from the source parent and attaches to the destination
    sourceParent.children = sourceParent?.children?.filter((child) => child.id !== source.id) || [];
    destination.children?.push(source);
    // Sorts by devices first, then by name using natural order
    destination.children.sort((a,b) => (+(a?.isDevice || false) - +(b?.isDevice || false)) || (a.name || '').localeCompare(b.name || '', "en", { numeric: true }))

    // If it's a device, also update the asset list
    if (source.isDevice) {
      sourceParent.assetList = sourceParent?.assetList?.filter((child) => child !== source.id) || [];
      destination.assetList?.push(source.id);
    }

    setHierarchyTree({ ...hierarchyTree });
    if (!expanded.includes(destination.id) && destination.isRendered) setExpandWith(destination.id);

    try {
      if (source.isDevice) {
        const detachDevice = deviceManagerAPI.patch(`${API_URL_PATH_TAG}/${sourceParent.id}`, {
          assetList: sourceParent?.assetList?.filter((asset: string) => asset !== source.id),
          runDuplicateAssetValidation: false,
        }, await getAuthConfig());

        const addDevice = deviceManagerAPI.patch(`${API_URL_PATH_TAG}/${destination.id}`, {
          assetList: [...(destination?.assetList || []), source.id],
          runDuplicateAssetValidation: false,
        }, await getAuthConfig());

        await Promise.all([detachDevice, addDevice]);
      }

      await handleSaveHierarchy();
      isUpdating.current = false;

    } catch (error: any) {
      setNotification([{
        type: 'error',
        content: `Unexpected error: ${error.message}. Please reload before attempting to do other updates.`,
      }]);
  }

  };

  const handleNodeDelete = async (node: HierarchyNode) => {
    if (!hierarchyTree || isUpdating.current) return;
    isUpdating.current = true;

    if (!window.confirm('Are you sure you want to delete this node?')) return;

    if (node?.assetList?.length) {
      setDevicesPresentInTree((prev: string[]) => prev.filter((asset: string) => !node?.assetList?.includes(asset)));
    }

    deleteNode(node, hierarchyTree);
    await handleSaveHierarchy();
    setHierarchyTree({ ...hierarchyTree });
    isUpdating.current = false;
  };

  const handleNodeAdded = async (parent: HierarchyNode) => {
    if (!hierarchyTree) return;
    await handleSaveHierarchy();
    setAllIds(allIds => !allIds.includes(parent.id) ? [...allIds, parent.id] : allIds);
    setExpandWith(parent.id);
    setHierarchyTree({ ...hierarchyTree });
  };

  const handleSaveHierarchy = async () => {
    if (!hierarchy || !hierarchyTree) {
      setNotification([
        {
          type: 'error',
          content: 'Hierarchy is not loaded',
        },
      ]);
      return;
    }
    await deviceManagerAPI.patch(`${API_URL_PATH_HIERARCHY}/${hierarchy?.id}`, {
      hierarchyTreeData: [toMinimalNode(hierarchyTree)],
    }, await getAuthConfig());
  };

  const handleDeviceDetach = async (deviceNode: HierarchyNode) => {
    if (!hierarchyTree) return;

    if (!window.confirm('Are you sure you want to detach this device?')) return;

    const { parent: tagContainingDevice } = findSourceNodeAndParentInTree(deviceNode, hierarchyTree);
    const tagDataResponse = await fetchTagById(`${API_URL_PATH_TAG}/${tagContainingDevice?.id}`);
    if (tagDataResponse === 'canceled') return;
    if (!tagDataResponse || !tagDataResponse.data) {
      setNotification([
        {
          type: 'error',
          content: 'Error fetching tag data after detach',
        },
      ]);
      return
    }
    const tagData = tagDataResponse.data;

    tagData.assetList = tagData.assetList.filter((asset: string) => asset !== deviceNode.name);
    setDevicesPresentInTree((prev: string[]) => prev.filter((asset: string) => asset !== deviceNode.name));

    const authConfig = await getAuthConfig();
    await deviceManagerAPI.patch(`${API_URL_PATH_TAG}/${tagContainingDevice?.id}`, {
      assetList: tagData.assetList,
      runDuplicateAssetValidation: false,
    }, authConfig);

    await handleSaveHierarchy();
    deleteNode(deviceNode, hierarchyTree);
    setChartDevices(chartDevices.filter((x) => x.name !== deviceNode.name));
    setHierarchyTree({ ...hierarchyTree });
  }

  useEffect(() => {
    if (devicesResponse?.items?.length && !devicesError) {
      setEnabledDevices(devicesResponse.items.filter((device) => device.deviceState === DeviceState.inService).map((device) => ({
        label: device.friendlyName || device.name || '',
        labelTag: device.name,
        value: device.name,
        description: device.description,
        disabled: devicesPresentInTree.includes(device.name),
      })).sort((a,b) => a.label.localeCompare(b.label, "en", { numeric: true })));
    }

  }, [devicesPresentInTree, devicesResponse, devicesError]);

  if (hierarchyError && !isHierarchyLoading) {
    return (
      <Alert severity="error">
        <AlertTitle>No data available</AlertTitle>
        {hierarchyError}
      </Alert>
    );
  }

  if (isHierarchyLoading || !hierarchyTree || isDevicesLoading) return <CircularProgress size={35} style={{ color: 'black' }} />;

  const deviceForDetails = devicesResponse.items.find(device => device.name === deviceDetailsModal);
  
  return (<>
    {deviceForDetails && <Modal
      onDismiss={() => setDeviceDetailsModal(null)}
      visible={deviceDetailsModal !== null}
      size="large"
      header={deviceForDetails?.friendlyName || deviceDetailsModal}>
      <DeviceTabs selectedDevices={[deviceForDetails]} />
    </Modal>}
    <DndProvider backend={HTML5Backend}>
      <SimpleTreeView
        onExpandedItemsChange={(_event, nodeIds) => setExpanded(nodeIds)}
        expandedItems={expanded}
        itemChildrenIndentation={8}
      >
        <HierarchyItem
          node={hierarchyTree!}
          isRootTag={true}
          hierarchyId={hierarchy!.id}
          enabledDevices={enabledDevices ?? []}
          devicesPresentInTree={devicesPresentInTree}
          isUpdating={isUpdating}
          onNodeDropped={handleNodeReorder}
          onNodeClick={handleNodeClick}
          onNodeDelete={handleNodeDelete}
          onDeviceDetach={handleDeviceDetach}
          onNodeAdded={handleNodeAdded}
          setDevicesPresentInTree={setDevicesPresentInTree}
          setDeviceDetailsModal={setDeviceDetailsModal}
        />
      </SimpleTreeView>
    </DndProvider>
  </>);
};

export default HierarchyTree;