import { FormField, Multiselect } from '@cloudscape-design/components';
import { OptionDefinition } from '@cloudscape-design/components/internal/components/option/interfaces';
import { deviceManagerAPI } from 'api';
import { API_URL_PATH_DM_CLAIMED } from 'constants/urls';
import useFetchWithReactQuery from 'hooks/useFetchWithReactQuery';
import { useEffect, useState } from 'react';
import { Asset } from 'types/custom';

const DeviceSelect = ({
    selectedAssets,
    setSelectedAssets,
    disabled,
}: {
    selectedAssets: readonly OptionDefinition[];
    setSelectedAssets: (state: readonly OptionDefinition[]) => void;
    disabled: boolean;
}) => {
    const [allAssets, setAllAssets] = useState<OptionDefinition[]>();

    const {
        data: claimedDevicesList,
        error: claimedDevicesError,
        status: claimedDevicesStatus,
        isLoading: claimedDevicesLoading,
    } = useFetchWithReactQuery<{ items: Asset[] }>({
        axiosInstance: deviceManagerAPI,
        url: API_URL_PATH_DM_CLAIMED,
        key: 'devices',
    });

    useEffect(() => {
        if (claimedDevicesList && claimedDevicesStatus === 'success') {
            setAllAssets(claimedDevicesList.items.map((device: Asset) => ({
                label: device.friendlyName,
                description: device.description,
                labelTag: device.name,
                value: device.name,
            })));
        }
    }, [claimedDevicesList, claimedDevicesStatus]);

    return (
        <FormField
            label={
                <span>
                    Devices <i>- optional</i>{' '}
                </span>
            }
        >
            <Multiselect
                selectedOptions={selectedAssets}
                onChange={({ detail }) =>
                    setSelectedAssets(detail.selectedOptions)
                }
                disabled={disabled}
                filteringType='auto'
                deselectAriaLabel={(e) => `Remove ${e.label}`}
                options={allAssets}
                placeholder='Choose options'
                selectedAriaLabel='Selected'
                loadingText='Loading assets'
                statusType={claimedDevicesLoading ? 'loading' : 'finished'}
                errorText={String(claimedDevicesError)}
                empty='No options'
            />
        </FormField>
    );
};

export default DeviceSelect;
