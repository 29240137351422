import { Auth } from "aws-amplify";
import { StateAlertConfigCode } from "types/custom";

export const selectFewerProps = (data: any) => {
    const { name: label, id: { id: value } = { id: {} } } = data;
    return { label, value };
};

export const selectLocationProps = (data: any, type: any) => {
    const code = data[type + 'no'] || data[type + 'code']
    return {
        id: data[type + 'id'],
        label: data[type] || data[type + 'name'] || data[type + 'id'],
        value: data[type + 'id'],
        labelTag: code ? `${code} [${data[type + 'id']}]` : data[type + 'id'],
        description: `Created at ${new Date(data.datecreated).toLocaleString().replace(',', '')}${data.datemodified ? '. Modified at ' + new Date(data.datemodified).toLocaleString().replace(',', '') : ''}`,
    };
};

export const addRecentlyUsed = (data: any, stored: any, selectUpTo: any[] = [], isLoading: boolean = false) => {
    let parsed = stored || [];
    selectUpTo = selectUpTo.filter(x => x !== undefined)
    while (selectUpTo.length > 0) {
        const selectedId = selectUpTo.shift()?.toString()
        parsed = parsed.find((loc: { [id: string]: string; }) => loc['id'] === selectedId)?.children || [];
    }
    parsed = parsed.map((loc: { [prop: string]: string; }) => ({
        label: loc['name'],
        value: Number(loc['id']),
        labelTag: loc['id'],
    }));
    return [{
        label: 'Recently Used',
        options: parsed
    }, {
        label: 'All Locations',
        options: !isLoading ? data : []
    }
    ];
}

export const getMatchesCountText = (count: number | undefined) => {
    return `${count} ${count === 1 ? 'match' : 'matches'}`;
};

export const convertUTCHourToLocal = (value: number, optional: boolean = false) => {
    if (optional && !value) return 0;
    const getMillisecondsForHour = new Date().setUTCHours(+value);
    return new Date(getMillisecondsForHour).getHours();
};

export const convertLocalHourToUTC = (value: string, optional: boolean = false) => {
    if (optional && !value) return 0;
    const getMillisecondsForHour = new Date().setHours(+value);
    return new Date(getMillisecondsForHour).getUTCHours();
};

export const getAuthConfig = async () => {
    const token = (await Auth.currentSession()).getIdToken().getJwtToken();
    const config = {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    };
    return config;
};

export function toTitleCase(str: string) {
    return str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
};

export function thresholdCodeToName(code?: StateAlertConfigCode) {
    switch (code) {
        case StateAlertConfigCode.LL:
            return 'Low-Low';
        case StateAlertConfigCode.L:
            return 'Low';
        case StateAlertConfigCode.H:
            return 'High';
        case StateAlertConfigCode.HH:
            return 'High-High';
        default:
            return ''
    }
}