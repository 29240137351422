import { Alert, Spinner } from '@cloudscape-design/components';
import ListItemComp from 'components/incident-manager/ListItem';
import { useIncidentManagerContext } from 'pages/incident-manager/IncidentManagerPage';
import React, { useEffect, useState } from 'react';

const GroupsList = () => {
    const { allGroups, setSelectedGroup, groupsLoading } =
        useIncidentManagerContext();

    const [selectedIndex, setSelectedIndex] = useState(0);

    useEffect(() => {
        if (allGroups) {
            setSelectedGroup(allGroups[0]);
            setSelectedIndex(0);
        }
    }, [allGroups]);

    const handleListItemClick = (index: number) => {
        setSelectedIndex(index);
    };

    return (
        <div style={{ minHeight: '600px' }}>
            {groupsLoading && <Spinner />}
            {!groupsLoading && !allGroups?.length && (
                <Alert>No notification groups found</Alert>
            )}

            <ListItemComp
                allItems={allGroups}
                selectedIndex={selectedIndex}
                handleListItemClick={handleListItemClick}
                setSelectedItem={setSelectedGroup}
                module='groups'
            />
        </div>
    );
};

export default GroupsList;
