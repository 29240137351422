import { ContentLayout, Grid } from '@cloudscape-design/components';

import DashboardProvider from 'providers/DashboardProvider';
import DashboardHeader from './dashboard-header';
import DashboardPage from './dashboard-page';
import colors from '../../theme/colors';

const Dashboard = () => {
    return (
        <DashboardProvider>
            <ContentLayout defaultPadding headerBackgroundStyle={colors.accentGreen2} header={<DashboardHeader />}>
                <Grid gridDefinition={[{ colspan: 12 }]}>
                    <DashboardPage />
                </Grid>
            </ContentLayout>
        </DashboardProvider>
    );
};

export default Dashboard;
