import {
    Box,
    Button,
    Modal,
    SpaceBetween,
} from '@cloudscape-design/components';

const DeleteModal = ({
    visible,
    onDiscard,
    onDelete,
    itemName,
    moduleName,
    loading,
}: {
    visible: boolean;
    onDiscard: () => void;
    onDelete: () => void;
    itemName: any[] | undefined;
    itemCount: any;
    moduleName: string;
    loading?: boolean;
}) => {
    const getNames = (itemName: any[] | undefined) => {
        const names = itemName!?.map((item) => {
            return ' ' + item['name'];
        });

        return names.toString();
    };

    return (
        <>
            <Modal
                onDismiss={onDiscard}
                visible={visible}
                closeAriaLabel='Close modal'
                footer={
                    <Box float='right'>
                        <SpaceBetween direction='horizontal' size='xs'>
                            <Button variant='link' onClick={onDiscard}>
                                Cancel
                            </Button>
                            <Button variant='primary' onClick={onDelete} loading={loading}>
                                Delete
                            </Button>
                        </SpaceBetween>
                    </Box>
                }
                header={`Delete ${moduleName}`}
            >
                Are you sure you want to delete the following{' '}
                {moduleName.toLowerCase()} permanently?{' '}
                <div>
                    <i>You can't undo this action.</i>
                </div>{' '}
                <b>
                    <ul>
                        {itemName!?.length > 1 ? (
                            getNames(itemName)
                        ) : (
                            <li>
                                {itemName![0]?.name ||
                                    itemName![0]?.friendlyName}
                            </li>
                        )}
                    </ul>
                </b>
            </Modal>
        </>
    );
};

export default DeleteModal;
