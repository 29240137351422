import {
    Button,
    Form,
    FormField,
    Header,
    Input,
    SpaceBetween,
    Spinner,
} from '@cloudscape-design/components';
import { incidentManagerAPI } from 'api';
import DeleteModal from 'components/delete-modal';
import TransferList from 'components/incident-manager/TransferList';
import { API_URL_PATH_IM_PLANS } from 'constants/urls';
import useFetch from 'hooks/useFetch';
import { useIncidentManagerContext } from 'pages/incident-manager/IncidentManagerPage';
import { useEffect, useState } from 'react';

const PlansView = () => {
    const { selectedPlan, setSelectedPlan, plansLoading, allGroups, plansRefetch, allPlans } =
        useIncidentManagerContext();
    const [planName, setPlanName] = useState('');
    const [planError, setPlanError] = useState('');
    const [description, setDescription] = useState('');
    const [stageDuration, setStageDuration] = useState<string>('');
    const [flowType, setFlowType] = useState('update');
    const [disableFormFields, setDisableFormFields] = useState(false);
    const [stages, setStages] = useState<any>([]);

    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const onDeleteDiscard = () => setShowDeleteModal(false);

    useEffect(() => {
        if (!plansLoading && !allPlans) {
            setFlowType('create');
        }
    }, [allPlans, plansLoading]);

    useEffect(() => {
        if (flowType === 'update') {
            setPlanName(selectedPlan?.name || '');
            setDescription(selectedPlan?.description || '');
        }
        setStageDuration('');
    }, [selectedPlan, flowType]);

    const validatePlanName = () => {
        const groupNameRegExp = /^[a-z0-9_\-](.|\s)*$/;
        const isValid = groupNameRegExp.test(planName);

        if (planName && isValid) {
            setPlanError('');
        } else {
            setPlanError('Enter a valid plan name');
        }

        return isValid;
    };

    const {
        error: createPlanError,
        loading: createPlanLoading,
        status: createPlanStatus,
        fetchData: createPlan,
        response: createPlanResponse,
    } = useFetch(
        {
            axiosInstance: incidentManagerAPI,
            method: 'POST',
            url: API_URL_PATH_IM_PLANS,
            data: {
                name: planName,
                description,
                stages,
            },
        },
        { manual: true }
    );

    const {
        error: updatePlanError,
        loading: updatePlanLoading,
        status: updatePlanStatus,
        fetchData: updatePlan,
        response: updatePlanResponse,
    } = useFetch(
        {
            axiosInstance: incidentManagerAPI,
            method: 'PATCH',
            url: `${API_URL_PATH_IM_PLANS}/${selectedPlan?.name}`,
            data: {
                description,
                stages,
            },
        },
        { manual: true }
    );

    const { loading: deletePlanLoading, fetchData: deletePlan } = useFetch(
        {
            axiosInstance: incidentManagerAPI,
            method: 'DELETE',
            url: `${API_URL_PATH_IM_PLANS}/${selectedPlan?.name}`,
        },
        { manual: true }
    );

    const {
        error: activatePlanError,
        loading: activatePlanLoading,
        status: activatePlanStatus,
        fetchData: activatePlan,
        response: activatePlanResponse,
    } = useFetch(
        {
            axiosInstance: incidentManagerAPI,
            method: 'PATCH',
            url: `${API_URL_PATH_IM_PLANS}/${selectedPlan?.name}`,
            data: {
                description: selectedPlan?.description,
                active: !selectedPlan?.active,
            },
        },
        { manual: true }
    );

    const handleCreateAction = (event: any) => {
        event.preventDefault();
        setFlowType('create');
        setPlanName('');
        setDescription('');
    };

    const handleDeleteAction = (event: any) => {
        event.preventDefault();
        setShowDeleteModal(true);
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const planNameValidation = validatePlanName();
        if (!planNameValidation) return;

        setDisableFormFields(true);

        if (flowType === 'update') {
            updatePlan();
        } else {
            createPlan();
        }
    };

    useEffect(() => {
        setDisableFormFields(false);
        if (
            updatePlanStatus === 200 ||
            createPlanStatus === 201 ||
            activatePlanStatus === 200
            // deletePlanStatus === 200
        ) {
            plansRefetch();
            setFlowType('update');
        } else if (
            updatePlanStatus > 201 ||
            createPlanStatus > 201
            // deletePlanStatus > 201
        ) {
            setDisableFormFields(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        createPlanStatus,
        createPlanResponse,
        updatePlanStatus,
        updatePlanResponse,
        activatePlanStatus,
        activatePlanResponse,
    ]);

    const onDeleteConfirm = () => {
        deletePlan()
            .then(() => {
                plansRefetch();
                setFlowType('update');
                setSelectedPlan(null);
            })
            .catch((err) => {
                console.error(err);
            });
        setShowDeleteModal(false);
    };

    return (
        <div style={{ minHeight: '700px' }}>
            {plansLoading && flowType === 'update' ? (
                <Spinner />
            ) : (
                <>
                    <form onSubmit={handleSubmit}>
                        <Form
                            actions={
                                <SpaceBetween direction='horizontal' size='xs'>
                                    {flowType === 'create' && (
                                        <Button
                                            formAction='none'
                                            variant='link'
                                            onClick={() => {
                                                setFlowType('update');
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                    )}
                                    <Button
                                        variant='primary'
                                        loading={
                                            createPlanLoading ||
                                            updatePlanLoading
                                        }
                                    >
                                        Submit
                                    </Button>
                                </SpaceBetween>
                            }
                            header={
                                <Header
                                    variant='h3'
                                    actions={
                                        flowType !== 'create' && (
                                            <SpaceBetween
                                                direction='horizontal'
                                                size='xs'
                                            >
                                                <Button
                                                    onClick={() => activatePlan()}
                                                    loading={activatePlanLoading}
                                                    disabled={!selectedPlan}
                                                >
                                                    {selectedPlan?.active ===
                                                        true
                                                        ? 'Disable'
                                                        : 'Activate'}
                                                </Button>
                                                <Button
                                                    onClick={handleDeleteAction}
                                                    loading={deletePlanLoading}
                                                    disabled={!selectedPlan}
                                                >
                                                    Delete
                                                </Button>
                                                <Button
                                                    variant='primary'
                                                    onClick={handleCreateAction}
                                                    loading={createPlanLoading}
                                                >
                                                    Create new
                                                </Button>
                                            </SpaceBetween>
                                        )
                                    }
                                >
                                    {flowType === 'update'
                                        ? 'Update'
                                        : 'Create'}{' '}
                                    Escalation Plan
                                </Header>
                            }
                            errorText={
                                createPlanError ||
                                updatePlanError ||
                                activatePlanError
                            }
                        >
                            <SpaceBetween direction='vertical' size='l'>
                                <FormField label='Name' errorText={planError}>
                                    <Input
                                        disabled={
                                            plansLoading ||
                                            disableFormFields ||
                                            flowType === 'update'
                                        }
                                        value={planName}
                                        onChange={(event) =>
                                            setPlanName(event.detail.value)
                                        }
                                        onBlur={validatePlanName}
                                    />
                                </FormField>

                                <FormField label='Description'>
                                    <Input
                                        disabled={disableFormFields}
                                        value={description}
                                        onChange={(event) =>
                                            setDescription(event.detail.value)
                                        }
                                    />
                                </FormField>

                                <FormField
                                    label='Stage Duration (minutes)'
                                    description='Select notification group(s) from below choices and enter the time'
                                    constraintText='On the last stage, 0 will end the escalation'
                                >
                                    <Input
                                        disabled={
                                            plansLoading || disableFormFields
                                        }
                                        value={stageDuration}
                                        onChange={(event) =>
                                            setStageDuration(
                                                event.detail.value
                                            )
                                        }
                                    />
                                </FormField>

                                <FormField
                                    label='Notification group(s)'
                                    description='Escalation will be stopped when the incident becomes acknowledged'
                                />
                                <TransferList
                                    allItems={allGroups}
                                    selectedItems={selectedPlan}
                                    itemType='plans'
                                    stageDuration={stageDuration}
                                    setStageDuration={setStageDuration}
                                    flowType={flowType}
                                    setChosen={setStages}
                                />
                                </SpaceBetween>
                            <br></br>
                        </Form>
                    </form>

                    <DeleteModal
                        visible={showDeleteModal}
                        onDiscard={onDeleteDiscard}
                        onDelete={onDeleteConfirm}
                        itemName={[selectedPlan]}
                        itemCount={1}
                        moduleName='Escalation Plan'
                    />
                </>
            )}
        </div>
    );
};

export default PlansView;
