import SideNavigation from '@cloudscape-design/components/side-navigation';
import { useState } from 'react';
import useLink from 'hooks/useLink';
import {
    URL_PATH_DASHBOARD,
    URL_FULL_PATH_USER_MANAGER_ROLES,
    URL_FULL_PATH_DEVICE_MANAGER_LIST,
    URL_FULL_PATH_INCIDENT_MANAGER_VIEW,
    URL_FULL_PATH_DEVICE_GROUP_MANAGER_LIST,
} from 'constants/urls';

const SideNav = () => {
    const { handleFollow } = useLink();
    const [activeHref, setActiveHref] = useState<string>(
        window.location.pathname
    );

    const handleLink = (event: Readonly<CustomEvent>) => {
        event.preventDefault();
        setActiveHref(event.detail.href);
        handleFollow(event);
    };

    return (
        <SideNavigation
            activeHref={activeHref}
            onFollow={handleLink}
            header={{
                text: 'Sensor Visualization, Alerting & Notification',
                href: '',
            }}
            items={[
                {
                    type: 'link',
                    text: 'Dashboard',
                    href: URL_PATH_DASHBOARD,
                },
                {
                    type: 'link',
                    text: 'Role Manager',
                    href: URL_FULL_PATH_USER_MANAGER_ROLES,
                },
                {
                    type: 'link',
                    text: 'Device Manager',
                    href: URL_FULL_PATH_DEVICE_MANAGER_LIST,
                },
                {
                    type: 'link',
                    text: 'Incident Manager',
                    href: URL_FULL_PATH_INCIDENT_MANAGER_VIEW,
                },
                {
                    type: 'link',
                    text: 'Device Group Manager',
                    href: URL_FULL_PATH_DEVICE_GROUP_MANAGER_LIST,
                },
                { type: 'divider' },
            ]}
        ></SideNavigation>
    );
};

export default SideNav;
