import { incidentManagerAPI } from 'api';
import { useSearchParams } from 'react-router-dom';

import {
    API_URL_PATH_IM_CONTACTS,
    API_URL_PATH_IM_GROUPS,
    API_URL_PATH_IM_PLANS,
} from 'constants/urls';
import { Contact, NotificationGroup, EscalationPlan } from 'types/custom';
import { createContext, useContext, useEffect, useState } from 'react';
import IncidentManagerMainTabs from 'components/incident-manager/IncidentManagerMainTabs';
import useFetchWithReactQuery from 'hooks/useFetchWithReactQuery';

const IncidentManagerContext = createContext({
    activeMainTabId: '',
    setActiveMainTabId: (state: string) => { },
    allContacts: [] as Contact[] | undefined,
    setAllContacts: (state: Contact[]) => { },
    selectedContact: {} as Contact | null,
    setSelectedContact: (state: Contact | null) => { },
    contactsRefetch: () => { },
    contactsLoading: false,
    contactsFetching: false,
    allGroups: [] as NotificationGroup[] | undefined,
    setAllGroups: (state: NotificationGroup[]) => { },
    selectedGroup: {} as NotificationGroup | null,
    setSelectedGroup: (state: NotificationGroup | null) => { },
    groupsRefetch: () => { },
    groupsLoading: false,
    groupsFetching: false,
    allPlans: [] as EscalationPlan[] | undefined,
    setAllPlans: (state: EscalationPlan[]) => { },
    selectedPlan: {} as EscalationPlan | null,
    setSelectedPlan: (state: EscalationPlan | null) => { },
    plansRefetch: () => { },
    plansLoading: false,
    plansFetching: false,
});

export const useIncidentManagerContext = () =>
    useContext(IncidentManagerContext);

const IncidentManagerPage = () => {
    const [activeMainTabId, setActiveMainTabId] = useState('contacts');

    const [allContacts, setAllContacts] = useState<Contact[]>();
    const [allGroups, setAllGroups] = useState<NotificationGroup[]>();
    const [allPlans, setAllPlans] = useState<EscalationPlan[]>();

    const [selectedContact, setSelectedContact] =
        useState<Contact | null>(null);
    const [selectedGroup, setSelectedGroup] = useState<NotificationGroup | null>(
        null
    );
    const [selectedPlan, setSelectedPlan] = useState<EscalationPlan | null>(null);

    const [searchParams] = useSearchParams();
    const tabId = searchParams.get('tab') || '';

    useEffect(() => {
        if (tabId) {
            setActiveMainTabId(tabId);
        }
    }, [tabId]);

    const {
        data: contactsList,
        isLoading: contactsLoading,
        isFetching: contactsFetching,
        status: contactsStatus,
        refetch: contactsRefetch,
    } = useFetchWithReactQuery<{ items: Contact[] }>(
        {
            axiosInstance: incidentManagerAPI,
            url: API_URL_PATH_IM_CONTACTS,
            key: 'contacts',
        }
    );

    const {
        data: groupsList,
        isLoading: groupsLoading,
        isFetching: groupsFetching,
        status: groupsStatus,
        refetch: groupsRefetch,
    } = useFetchWithReactQuery<{ items: NotificationGroup[] }>(
        {
            axiosInstance: incidentManagerAPI,
            url: API_URL_PATH_IM_GROUPS,
            key: 'groups',
        }
    );

    const {
        data: plansList,
        isLoading: plansLoading,
        isFetching: plansFetching,
        status: plansStatus,
        refetch: plansRefetch,
    } = useFetchWithReactQuery<{ items: EscalationPlan[] }>(
        {
            axiosInstance: incidentManagerAPI,
            url: API_URL_PATH_IM_PLANS,
            key: 'escalation-plans',
        }
    );

    useEffect(() => {
        if (contactsStatus === 'success' && !contactsFetching) {
            setAllContacts(contactsList?.items || []);
            setSelectedContact(contactsList?.items && contactsList?.items[0]);
        }
    }, [contactsStatus, contactsList, contactsFetching]);

    useEffect(() => {
        if (groupsStatus === 'success' && !groupsFetching) {
            setAllGroups(groupsList?.items || []);
            setSelectedGroup(groupsList?.items && groupsList?.items[0]);
        }
    }, [groupsStatus, groupsList, groupsFetching]);

    useEffect(() => {
        if (plansStatus === 'success' && !plansFetching) {
            setAllPlans(plansList?.items || []);

            if (!selectedPlan) {
                setSelectedPlan(plansList?.items && plansList?.items[0]);
            }
        }
    }, [plansStatus, plansList, plansFetching]);

    return (
        <IncidentManagerContext.Provider
            value={{
                activeMainTabId,
                setActiveMainTabId,
                allContacts,
                setAllContacts,
                selectedContact,
                setSelectedContact,
                contactsRefetch,
                contactsLoading,
                contactsFetching,
                allGroups,
                setAllGroups,
                selectedGroup,
                setSelectedGroup,
                groupsRefetch,
                groupsLoading,
                groupsFetching,
                allPlans,
                setAllPlans,
                selectedPlan,
                setSelectedPlan,
                plansRefetch,
                plansLoading,
                plansFetching,
            }}
        >
            <div style={{ backgroundColor: '#fff' }}>
                <IncidentManagerMainTabs />
            </div>
        </IncidentManagerContext.Provider>
    );
};

export default IncidentManagerPage;
