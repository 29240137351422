import { useEffect, useState } from 'react';
import { useCollection } from '@cloudscape-design/collection-hooks';
import { useNavigate } from 'react-router-dom';
import {
    Button,
    Table,
    TextFilter,
    Header,
    SpaceBetween,
    ButtonDropdown,
    Pagination,
} from '@cloudscape-design/components';

import { URL_PATH_USER_MANAGER_ROLES_CREATE, API_URL_PATH_UM_ROLES } from 'constants/urls';
import { useUserManagerContext } from 'pages/role-manager/RolesListPage';
import { usePageLayoutContext } from 'components/common/layout';
import { EmptyState } from 'components/empty-state/EmptyState';
import { COLUMN_DEFINITIONS } from './table-config';
import DeleteModal from 'components/delete-modal';
import { getMatchesCountText } from 'utils';
import useFetch from 'hooks/useFetch';
import { userManagerAPI } from 'api';

const RolesTable = () => {
    const { allItems, loading, setSelectedItem, selectedItem, refetch } = useUserManagerContext();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const onDeleteDiscard = () => setShowDeleteModal(false);
    const navigate = useNavigate();

    const { setNotification } = usePageLayoutContext();

    const {
        items,
        actions,
        filterProps,
        paginationProps,
        collectionProps,
        filteredItemsCount,
    } = useCollection(allItems, {
        filtering: {
            empty: (
                <EmptyState
                    title='No instances'
                    subtitle='No instances to display.'
                    action={
                        <Button
                            onClick={() =>
                                navigate(URL_PATH_USER_MANAGER_ROLES_CREATE, {
                                    state: {
                                        action: 'create',
                                    },
                                })
                            }
                        >
                            Create a Role
                        </Button>
                    }
                />
            ),
            noMatch: (
                <EmptyState
                    title='No matches'
                    subtitle='We can’t find a match.'
                    action={
                        <Button onClick={() => actions.setFiltering('')}>
                            Clear filter
                        </Button>
                    }
                />
            ),
        },
        pagination: { pageSize: 10 },
        sorting: {},
    });

    useEffect(() => {
        if (!selectedItem![0]?.name) {
            setSelectedItem([
                allItems.length > 0 && allItems[0]?.name ? allItems[0] : [],
            ]);
        }
    }, [allItems]);

    const { fetchData: deleteRole, loading: isDeletingRole } = useFetch(
        {
            axiosInstance: userManagerAPI,
            method: 'DELETE',
            url: `${API_URL_PATH_UM_ROLES}/${selectedItem?.length ? selectedItem![0]?.name : ''
                }`,
        },
        { manual: true }
    );

    const handleButtonDropdownClick = (event: any) => {
        event.preventDefault();

        if (event.detail.id === 'edit-role') {
            navigate('create', {
                state: {
                    action: 'edit',
                    role: selectedItem![0],
                },
            });
        }

        if (event.detail.id === 'delete-role') {
            setShowDeleteModal(true);
        }
    };

    const onDeleteConfirm = () => {
        deleteRole()
            .then((response) => {
                refetch();
                setNotification([
                    {
                        type: 'success',
                        content: `Deleted role ${selectedItem![0]?.name} successfully`,
                    },
                ]);
                setSelectedItem([]);
                setShowDeleteModal(false);
            })
            .catch((err) => {
                console.error(err);
            });
    };

    return (
        <>
            <>
                <Table
                    {...collectionProps}
                    onSelectionChange={({ detail }) =>
                        setSelectedItem(detail.selectedItems)
                    }
                    selectedItems={selectedItem}
                    loading={loading}
                    wrapLines={true}
                    stripedRows={true}
                    columnDefinitions={COLUMN_DEFINITIONS}
                    items={items}
                    variant='container'
                    loadingText='Loading resources'
                    selectionType='single'
                    trackBy='name'
                    filter={
                        <TextFilter
                            {...filterProps}
                            countText={getMatchesCountText(filteredItemsCount)}
                            filteringPlaceholder='Find resources'
                        />
                    }
                    header={
                        <Header
                            counter={
                                selectedItem?.length && selectedItem[0]?.name
                                    ? '(' +
                                    selectedItem.length +
                                    `/${allItems && allItems.length})`
                                    : `(${allItems && allItems.length})`
                            }
                            actions={
                                <SpaceBetween direction='horizontal' size='xs'>
                                    <Button
                                        onClick={() =>
                                            navigate('create', {
                                                state: {
                                                    action: 'create',
                                                },
                                            })
                                        }
                                    >
                                        Create
                                    </Button>
                                    <ButtonDropdown
                                        items={[
                                            {
                                                text: 'Edit',
                                                id: 'edit-role',
                                                disabled:
                                                    allItems?.length === 0,
                                            },
                                            {
                                                text: 'Delete',
                                                id: 'delete-role',
                                                disabled:
                                                    allItems?.length === 0,
                                            },
                                        ]}
                                        onItemClick={handleButtonDropdownClick}
                                    >
                                        Actions
                                    </ButtonDropdown>
                                </SpaceBetween>
                            }
                        >
                            List of roles
                        </Header>
                    }
                    pagination={
                        <Pagination
                            {...paginationProps}
                            ariaLabels={{
                                nextPageLabel: 'Next page',
                                previousPageLabel: 'Previous page',
                                pageLabel: (pageNumber) =>
                                    `Page ${pageNumber} of all pages`,
                            }}
                        />
                    }
                />

                <DeleteModal
                    visible={showDeleteModal}
                    onDiscard={onDeleteDiscard}
                    onDelete={onDeleteConfirm}
                    itemName={selectedItem}
                    itemCount={selectedItem?.length}
                    moduleName='Role'
                    loading={isDeletingRole}
                />
            </>
        </>
    );
};

export default RolesTable;
