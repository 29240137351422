import { Theme } from '@cloudscape-design/components/theming'
import colors from './colors'

const theme: Theme = {
  tokens: {
    colorTextButtonNormalDefault: colors.accentGreen2,
    colorBorderButtonNormalDefault: colors.accentGreen2,
    colorTextAccent: colors.accentGreen2,
    colorTextLinkButtonNormalDefault: colors.darkGreen,
    colorBackgroundControlChecked: colors.accentGreen2,
    colorBorderSegmentDefault: colors.accentGreen2,
    colorTextSegmentDefault: colors.accentGreen2,
    colorBorderSegmentActive: colors.accentGreen2,
    colorTextSegmentHover: colors.accentGreen,
    colorBackgroundSegmentActive: colors.accentGreen2,
    colorBackgroundToggleCheckedDisabled: colors.darkGreen3,
    colorTextStatusSuccess: colors.success,
    colorTextStatusError: colors.error,
    colorTextStatusWarning: colors.warning2,
    colorTextStatusInfo: colors.information,
  },
  contexts: {
    "top-navigation": {
      tokens: {
        colorBackgroundContainerContent: colors.darkGreen,
      }
    },
    "header": {
      tokens: {
        colorBackgroundLayoutMain: colors.darkGreen,
      }
    },
  }
}

export default theme
