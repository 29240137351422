import { DateRangePickerProps } from "@cloudscape-design/components";

export const notificationFrequencyProps: DateRangePickerProps = {
	relativeOptions: [
		{
			key: "always-renotify",
			amount: 0,
			unit: "second",
			type: "relative"
		},
		{
			key: "previous-30-minutes",
			amount: 30,
			unit: "minute",
			type: "relative"
		},
		{
			key: "previous-6-hours",
			amount: 6,
			unit: "hour",
			type: "relative"
		},
		{
			key: "previous-1-day",
			amount: 1,
			unit: "day",
			type: "relative"
		}
	],
	i18nStrings: {
		customRelativeRangeDurationLabel: 'Duration',
		customRelativeRangeDurationPlaceholder: 'Enter duration',
		customRelativeRangeOptionLabel: 'Custom interval',
		customRelativeRangeUnitLabel: 'Unit of time',
		formatRelativeRange: (e) => {
			const n = 1 === e.amount ? e.unit : `${e.unit}s`;
			return e.amount ? `${e.amount} ${n}` : 'Always renotify';
		},
		formatUnit: (e, n) => (1 === n ? e : `${e}s`),
		relativeRangeSelectionHeading: 'No new notifications for',
		clearButtonLabel: 'Never renotify',
		cancelButtonLabel: 'Cancel',
		applyButtonLabel: 'Apply',
	},
	rangeSelectorMode: 'relative-only',
	placeholder: 'Never renotify',
	isValidRange: range => !isNaN((range as DateRangePickerProps.RelativeValue)?.amount) ? ({ valid: true }) : ({ valid: false, errorMessage: 'Duration cannot be empty' }),
	value: null
}

export const secondsToRelative = (seconds: number|undefined, zeroIsNull: boolean = false): DateRangePickerProps.RelativeValue | null => {
	if (seconds === undefined) seconds = 0;
	if (seconds === -1) return null;
	if (zeroIsNull && seconds === 0) return null;
	if (seconds < 60) return { type: 'relative', amount: seconds, unit: 'second' };
	if (seconds < 3600) return { type: 'relative', amount: seconds / 60, unit: 'minute' };
	if (seconds < 86400) return { type: 'relative', amount: seconds / 3600, unit: 'hour' };
	if (seconds < 604800) return { type: 'relative', amount: seconds / 86400, unit: 'day' };
	if (seconds < 2628000) return { type: 'relative', amount: seconds / 604800, unit: 'week' };
	if (seconds < 31536000) return { type: 'relative', amount: seconds / 2628000, unit: 'month' };
	return { type: 'relative', amount: seconds / 31536000, unit: 'year' };
}

export const relativeToText = (relative: DateRangePickerProps.RelativeValue | null, disabled: string = 'Disabled', notExists: string = 'Not exists') => {
	if (!relative) return notExists;
	if (!relative.amount) return disabled;
	return `${relative.amount} ${relative.amount === 1 ? relative.unit : relative.unit + 's'}`;
}

export const relativeToSeconds = (relative: DateRangePickerProps.RelativeValue | null, defaultIfNull: number = -1) => {
	if (relative === null) return defaultIfNull;
	const { amount, unit } = relative;
	switch (unit) {
		case 'second': return amount;
		case 'minute': return amount * 60;
		case 'hour': return amount * 3600;
		case 'day': return amount * 86400;
		case 'week': return amount * 604800;
		case 'month': return amount * 2628000;
		case 'year': return amount * 31536000;
		default: return -1;
	}
}